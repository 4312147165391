<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="sendInvoicesForm"
        ref="sendInvoicesForm"
        class="w-100 pt-6"
        @submit.prevent="submitSendInvoicesForm"
      >
        <v-col cols="12">
          <v-progress-linear
            v-if="isFetchingClient"
            indeterminate
            absolute
            top
            height="3"
          />
          <ForwardDocument
            :contact-emails="contactEmails"
            :disabled="isFetchingClient"
            type="invoice"
            @update="updateContacts"
          />
          <v-checkbox
            v-model="attachUnpaidInvoices"
            label="Załącz informację o zaległościach"
            hide-details
            class="mt-0"
          />
          <v-checkbox
            v-model="withDebrisInfo"
            label="Pokaż informacje o odpadach w zestawieniu"
            hide-details
            class="mt-0"
          />
          <v-checkbox
            v-model="withInvoiceReport"
            label="Pokaż informacje o kursach w zestawieniu"
            hide-details
            class="mt-0"
          />
          <Warning
            v-if="isPayerIdUnmatched"
            message="Dokumenty mają różnych płatników"
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="isPayerIdUnmatched || !contactEmails.length"
        type="submit"
        form="sendInvoicesForm"
      >
        {{ label }}
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import ForwardDocument from '../Forms/Partials/ForwardDocument'
import rules from '../../utils/validators'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper,
    ForwardDocument,
  },
  mixins: [afterFormSubmitted],
  data() {
    return {
      rules,
      attachUnpaidInvoices: false,
      withDebrisInfo: false,
      withInvoiceReport: false,
      contactEmails: [],
      invoiceIds: [],
      isFetchingClient: false,
    }
  },
  async created() {
    this.clearSingleClient()
    if (this.payerId && !this.isPayerIdUnmatched) {
      this.isFetchingClient = true
      await this.getClient(this.payerId)
      this.isFetchingClient = false
    }
  },
  beforeDestroy() {
    this.clearSingleClient()
  },
  computed: {
    ...mapState({
      isProcessing: state => state.invoice.isProcessing,
      invoice: state => state.layout.dialog.item,
      invoices: state => state.invoices.items,
      label: state => state.layout.dialog.data.text,
      selectedInvoices: state => state.invoices.selectedItems,
    }),
    payerId() {
      const invoice = this.selectedInvoices.length ? this.invoices.find(invoice => this.selectedInvoices.includes(invoice.id)) : this.invoice
      return invoice.orders[0].payerId
    },
    isPayerIdUnmatched() {
      if (!this.selectedInvoices.length) return false
      const payerIds = this.invoices.filter(invoice => this.selectedInvoices.includes(invoice.id)).map(invoice => invoice.orders[0].payerId)
      return Array.from(new Set(payerIds)).length !== 1
    },
  },
  methods: {
    ...mapActions({
      clearSingleClient: 'client/clearSingleClient',
      getClient: 'client/getSingleClient',
      sendInvoices: 'invoice/sendInvoices',
    }),
    updateContacts(contactEmails) {
      this.contactEmails = contactEmails
    },
    submitSendInvoicesForm() {
      if (this.$refs.sendInvoicesForm.validate()) {
        const invoiceIds = this.selectedInvoices.length ? this.selectedInvoices : [this.invoice.id]
        this.sendInvoices({
          contactEmails: this.contactEmails,
          attachUnpaidInvoices: this.attachUnpaidInvoices,
          withDebrisInfo: this.withDebrisInfo,
          withInvoiceReport: this.withInvoiceReport,
          invoiceIds,
        })
          .then(() => { this.afterFormSubmitted('Wysłano dokument(y)') })
      }
    },
  },
}
</script>
